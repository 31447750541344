import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HOME_ROUTE } from '../../main/routeTypes';
import {attemptSwitchCompany} from '../store/actions/action';
import useFormApi from '../../../view/cmn/docAutomation/useFormApi'
import SarasPopup from '../../../library/container/SarasPopup'
import DocEditorSectionContainer from '../../../view/cmn/DocEditorSectionContainer';
import SarasTrans from '../../../library/notifications/SarasTrans';
import {IconCompany} from '../../../library/decor/SarasIcons';
import * as fn from "../../../helper/uiFunctions";

const CompanySwitcherPopup = (props) => {
    const { companyId, targetRef, onChange, onCancel } = props

    const [companyList, setCompanyList] = useState(null);
    const formApi = useFormApi();

    // console.log("company", companyList);

    const getCompanyList = () => {
        if(!companyList)
        {
            //request for the cultures list from the server
            let customAction = { actionId: "get", actionName: "userData", actionParam: {'content-type':'company-list'}, containerKey:""};
            formApi.runGenericAction(customAction, "userData", null, null, null, "", "", 0, onListSuccess);
        }
    };

    useEffect(getCompanyList);

    const onListSuccess = (action, containerKey, rowKey, headerKey, response) => {
        // console.log("company list response", response);
    
        if(response){
            setCompanyList(response);
        }
    }

    const handleClose = (event) => {
        // setOpen(false);
        onCancel();
    };

    const cancelFun = () => {
        onCancel();
    }

    const onCompanyChange = (c) => {
        onChange(c);
    }

    const renderRow = (c, index) => {
        if(fn.compareNumber(c.companyId, companyId)){
            return(
                <div className="uiSelectedListRow" key={index}>
                    <span className='uiActive'>{c.title}</span>
                </div>
            )
        }

        return (
            <div className="uiListRow" key={index}>
                <span onClick={() => {onCompanyChange(c)}} >{c.title}</span>
            </div>
        )
    }

    return (
        <SarasPopup anchorRef={targetRef} open={true} onClose={handleClose}>
            <DocEditorSectionContainer formStyle={{ width: '350px', height:'250px' }} 
                contentStyle={{ height:'150px', overflow:'hidden' }}  title={<SarasTrans value='Switch Company' />} 
                cancelName='Cancel' cancelFunction={cancelFun} >
                <div className="uiCompanyList">
                    <div className='uiBold uiSpacer20'>Select the company</div>
                    {companyList && companyList.map((c, index) => (
                        <div key={index}>
                            {renderRow(c, index)}
                        </div>
                    ))}
                </div>
            </DocEditorSectionContainer>
        </SarasPopup>
    )
}

const CompanySwitcher = (props)=> {
    const {currentPath} = props;

    const switcherRef = useRef();
    const [popupName, setPopupName] = useState('');
    
    const dispatchLogin = useDispatch();

    var user = useSelector(state => state.auth.user);
    var companyName;
    var companyId;
    var isMultipleCompany;

     //console.log("Company switcher", user);

    if (user != null){
        companyName = user.Company;
        companyId = user.CompanyId;
        isMultipleCompany = user.IsMultipleCompany;
    }
    //console.log("company current path", currentPath);
    const changeSwitcherClicked = () => {
            setPopupName("show");
    }

    const onConfirmChange = (company) => {
        dispatchLogin(attemptSwitchCompany(company.companyId));
        setPopupName("");
    }

    // const onChangeSuccess = (action, containerKey, rowKey, headerKey, response) => {
    //     console.log("Company change response", response);
    
    //     setPopupName("");
    //     setErrorMsg(null);
    //   }

    // const onChangeError = (action, containerKey, rowKey, headerKey, response) => {
    //     // console.log("company error", response)
    //     setErrorMsg({msgType: response.isError, msg:response.msg, messageList:response.messageList || {}});
    // }

    const onCancelChange = () => {
        setPopupName("");
    }

    const renderPopup = () => {
        if (popupName === 'show') {
            return (
                <CompanySwitcherPopup targetRef={switcherRef} 
                    currentCompany={companyName}
                    companyId = {companyId}
                    onChange={onConfirmChange}
                    onCancel={onCancelChange} >
                </CompanySwitcherPopup>
            );
        }

        return null;
    }

    if (isMultipleCompany === 1 && currentPath === HOME_ROUTE){
        return(
            <>
                <span ref={switcherRef} className="uiAppIconWithLink" onClick={changeSwitcherClicked}>
                    <IconCompany className='uiAppIcon'/>
                    <span>{companyName}</span>
                </span>
                {renderPopup()}
            </>
        )
    }
    else{
        return(
            <>
                <span className="uiAppIconWithTitle">
                    <IconCompany className='uiAppIcon'/>
                    <span>{companyName}</span>
                </span>
            </>
        )
    }
}
export default CompanySwitcher;