import React from 'react';
import SarasButton from '../../library/input/buttons/button/SarasButton2';
import SarasConfirmPrompt from '../../library/notifications/SarasConfirmPrompt';
import SarasTrans from '../../library/notifications/SarasTrans';

////uiForm
const DocEditorSectionContainer = (props)=>{
    const {formStyle, title, 
        actionName, cancelName, closeName, 
        confirmAction, confirmCancel, confirmClose,
        actionFunction, cancelFunction, closeFunction, 
        actionKSC, cancelKSC, closeKSC, contentStyle, children} = props

    const [confirmPromptData,setConfirmPromptData] = React.useState({})

    const onActionClick = (e) => {
        if(confirmAction && confirmAction.length > 0)
        {
            setConfirmPromptData({message:confirmAction, callback:executeOnAction});
        }
        else{
            executeOnAction()
        }
    }

    const onCancelClick = (e) => {
        if(confirmCancel && confirmCancel.length > 0)
        {
            setConfirmPromptData({message:confirmCancel, callback:executeOnCancel});
        }
        else{
            executeOnCancel()
        }
    }

    const onCloseClick = (e) => {
        if(confirmClose && confirmClose.length > 0)
        {
            setConfirmPromptData({message:confirmClose, callback:executeOnClose});
        }
        else{
            executeOnClose()
        }
    }    

    const executeOnAction = (e) => {
        if(actionFunction && typeof actionFunction === 'function') {
            actionFunction(e);
        }
    }

    const executeOnCancel = (e) => {
        if(cancelFunction && typeof cancelFunction === 'function') {
            cancelFunction(e);
        }
    }

    const executeOnClose = (e) => {
        if(closeFunction && typeof closeFunction === 'function') {
            closeFunction(e);
        }
    }
    
    const renderViewActions = () => {
        if(actionName || cancelName || closeName )
            return(
                <div className="phViewAction uiDialog">
                    <hr></hr>
                    <div className="uiDialogAction" style={{clear: "both"}}>
                        <div className="phAction" style={{float: "right"}}>
                            {
                                !actionName ? '' : <SarasButton ksc={actionKSC} className="uiSaveAction" 
                                                    style={{margin: "0 5px"}} 
                                                    onClick={onActionClick}>{actionName}</SarasButton>
                            }
                            {
                                !cancelName ? '' : <SarasButton ksc={cancelKSC} className="uiCancelAction" 
                                                    style={{margin: "0 5px"}} 
                                                    onClick={onCancelClick}>{cancelName}</SarasButton>
                            }
                            {
                                !closeName ? '' : <SarasButton ksc={closeKSC} className="uiCancelAction" 
                                                    style={{margin: "0 5px"}} 
                                                    onClick={onCloseClick}>{closeName}</SarasButton>
                            }
                        </div>
                        <div style={{clear: "both"}} />
                    </div>
                </div>
            )
    }

    return(
        <div className="phViewArea" style={formStyle ? formStyle : {}}>
            <div className='phPopupContainer'>
                <div className="phViewHeading">
                    <div className="phTitle">
                        <span><SarasTrans value = {title}/></span>
                    </div>
                </div>
                <div className="phPopupContent" style={contentStyle ? contentStyle : {}}>
                    {children}
                </div>
                <SarasConfirmPrompt promptData={confirmPromptData} />
                {renderViewActions()}
            </div>
        </div>
    )
}

export default DocEditorSectionContainer