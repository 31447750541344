import SarasErpApi from '../../../helper/SarasErpApi';
import * as fn from "../../../helper/uiFunctions";
import * as formJS from './formFunctions';

const useFormApi = function () {
    let runGenericAction = function (action, actionName, viewData, viewHeaderData, orgData, rowKey, headerKey, attachData, onActionSuccess, onActionError) {
        onActionSuccess = formJS.getOnSuccessCallBack(action, onActionSuccess);
        let customActionName = formJS.getCustomActionName(action, actionName);
        let containerKey = formJS.getCustomContainerKey(action);
        let viewRow;

        if(headerKey)
        {
            viewRow = formJS.getFormRow(viewHeaderData, headerKey);
        }
        else
        {
            viewRow = formJS.getFormRow(viewData, rowKey);
        }

        let rowData = {};
        let params = formJS.generateRequestParams(action, viewRow);
        
        if(fn.compareNumber(attachData, 1)) rowData = formJS.getFormRowKeyValue(viewRow);
        
        // console.log("withView master keys", orgData ? orgData.masterKeys : "");
        let masterKeys = {};

        if(orgData && orgData.masterKeys){
            if(!action.skipMasterKey || fn.compareNumber(action.skipMasterKey, 0)){
                masterKeys = {
                    masterKeys: {
                        ...orgData.masterKeys
                    }
                }
            }
        }

        sendApiRequest(action, customActionName, orgData, masterKeys, params, rowData, rowKey, headerKey, containerKey, onActionSuccess, onActionError);
    }

    let sendApiRequest = function(action, actionName, orgData, masterKeys, params, data, rowKey, headerKey, containerKey, onSuccessCallBack, onErrorCallBack, onSuccessRedirect, onErrorRedirect) {

        let pathKey = "";

        if(action.actionParam && action.actionParam.pathKey){
            pathKey = action.actionParam.pathKey;
        }
        else if(orgData && orgData.pathKey){
            pathKey = orgData.pathKey;
        }

        let viewKey = "";
        let currentView = "";

        if(action.actionParam && action.actionParam.viewKey){
            viewKey = action.actionParam.viewKey;
        }

        if(orgData && orgData.viewKey){
            currentView = orgData.viewKey;
        }

        let requestObject = {
            actionName: actionName,
            pathKey: pathKey,
            viewKey: viewKey,
            currentView: currentView,
            viewDataReload:action.viewDataReload || 0,
            viewData: {
                ...masterKeys
            },
            ...params,
            dataList:{
                ...data
            }
        }

        SarasErpApi.post('core', requestObject).then((response) => {
            // console.log('form api request', requestObject, "for action", action, response.data);
            // console.log("response 1", response);
            postResponseSequence(response, action, actionName, masterKeys, params, data, rowKey, headerKey, containerKey, onSuccessCallBack, onErrorCallBack, onSuccessRedirect, onErrorRedirect);
        });
    }

    let postResponseSequence = function(response, action, actionName, masterKeys, params, data, rowKey, headerKey, containerKey, onSuccessCallBack, onErrorCallBack, onSuccessRedirect, onErrorRedirect) {
        // console.log("response 2", response);

        // if(response && response.data){
        //     console.log('form api call response received', response.data, response.data.mainViewData ? response.data.mainViewData.viewKey : 'empty', actionName);
        // }

        if(response && response.data){
            //TODO if not logged in, then redirect to signin page

            if(fn.compareNumber(response.data.isError, 1)){
                if (onErrorCallBack) {
                    onErrorCallBack(action, containerKey, rowKey, headerKey, response.data);
                }
                else if (onErrorRedirect) {
                    // history.push(onErrorRedirect);
                }
            }
            else{
                if (onSuccessCallBack) {
                    onSuccessCallBack(action, containerKey, rowKey, headerKey, response.data);
                }
                else if (onSuccessRedirect) {
                    // history.push(onSuccessRedirect);
                }
            }
        }
    }

    let runUploadAction = function (action, actionName, viewData, viewHeaderData, orgData, rowKey, headerKey, onActionSuccess, onActionError) {
        onActionSuccess = formJS.getOnSuccessCallBack(action, onActionSuccess);
        let customActionName = formJS.getCustomActionName(action, actionName);
        let viewRow;

        if(headerKey)
        {
            viewRow = formJS.getFormRow(viewHeaderData, headerKey);
        }
        else
        {
            viewRow = formJS.getFormRow(viewData, rowKey);
        }

        let params = formJS.generateRequestParams(action, viewRow);
        
        // console.log("request param objects", params, "view row", viewRow, "row data", rowData);
        let masterKeys = {};

        if(orgData && orgData.masterKeys){
            if(!action.skipMasterKey || fn.compareNumber(action.skipMasterKey, 0)){
                masterKeys = {
                    ...orgData.masterKeys
                }
            }
        }

        sendUploadRequest(action, customActionName, orgData, masterKeys, params, rowKey, headerKey, onActionSuccess, onActionError);
    }

    let sendUploadRequest = function(action, actionName, orgData, masterKeys, params, rowKey, headerKey, onSuccessCallBack, onErrorCallBack, onSuccessRedirect, onErrorRedirect) {
        let pathKey = "";

        if(action.actionParam && action.actionParam.pathKey){
            pathKey = action.actionParam.pathKey;
        }
        else if(orgData && orgData.pathKey){
            pathKey = orgData.pathKey;
        }

        let viewKey = "";
        let currentView = "";

        if(action.actionParam && action.actionParam.viewKey){
            viewKey = action.actionParam.viewKey;
        }

        if(orgData && orgData.viewKey){
            currentView = orgData.viewKey;
        }

        var formdata = new FormData();

        Object.values(action.fileList).forEach((file)=>{
            formdata.append(file.name, file);
        })

        formdata.set("actionName", actionName);
        formdata.set("pathKey", pathKey);
        formdata.set("viewKey", viewKey);
        formdata.set("currentView", currentView);

        //append master key
        Object.keys(masterKeys).forEach((key) => {
            formdata.set(key, masterKeys[key]);
        });
        
        Object.keys(params).forEach((key) => {
            formdata.set(key, params[key]);
        });
        
        // console.log('form api request', formdata, "for action", action, masterKeys, params);

        SarasErpApi.post('rs', formdata).then((response) => {
            // console.log("response 1", response);
            postResponseSequence(response, action, actionName, masterKeys, params, null, rowKey, headerKey, null, onSuccessCallBack, onErrorCallBack, onSuccessRedirect, onErrorRedirect);
        });
    }

    let returnObj = {
        runGenericAction,
        runUploadAction
    };

    return returnObj;
}

export default useFormApi;
