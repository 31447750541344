import axios from 'axios';
import * as fn from './uiFunctions';

export default axios.create({
    //get build url automatically from the server url
    baseURL: `${fn.getBaseAPIURL()}`,
    headers: {
        post: {        // can be common or any other method
            "s-saras-app-id": "SARASerp-FE",
            "s-saras-auth": "1",
            "s-saras-timezone-offset": `${fn.getTimezoneOffset()}`,
            "s-saras-device-token": `${fn.getCookie("SARASDevice")}`
        }
      }
});
