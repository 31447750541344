import React, {useEffect, useState} from 'react';
import SarasAppBar from '../../../library/container/appBar/SarasAppBar';
import SarasTrans from '../../../library/notifications/SarasTrans';
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'

const PageFooter = (props) => {
    const [version, setVersion] = useState("");

    const style={
      appbar:{zIndex: '1201',height:'25px',position:'fixed',top:'calc(100% - 25px)',backgroundColor: "#504e4e"}
    }

    const localDb = useSarasStore();

    const checkIfVersionChanged = () => {
        if(!version){
            localDb.getAppVersion(onLocalVersionSuccess);
        }
    };

    useEffect(checkIfVersionChanged, [version]);

    const onLocalVersionSuccess = (key, value) =>  {
        if(value){
            setVersion(value);
        }
    }

    return(
        <SarasAppBar position="fixed" style={style.appbar} toolbarStyle={{minHeight: '20px'}}>
            <h6 className="uiAppFooter">
                <span>&copy; <a href="https://sarasennovations.com/" target='#' style={{color:'white',textDecoration:'none'}}>SARAS ennovations 2014-{new Date().getFullYear()}</a></span>
                <span className='AlignRight'><span><a href="https://saraserp.com" target='#' style={{color:'white',textDecoration:'none'}}><SarasTrans value="Powered by" /> SARAS erp</a></span> v{version}</span>
            </h6>
        </SarasAppBar>
    );
}

export default PageFooter